@font-face {
    font-family: 'Paytone One';
    src: url("../../assets/font/PaytoneOne/PaytoneOne-Regular.woff2") format('woff2'),
    url("../../assets/font/PaytoneOne/PaytoneOne-Regular.woff") format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rock Salt';
    src: url("../../assets/font/rock-salt/RockSalt-Regular.woff") format('woff'),
    url("../../assets/font/rock-salt/RockSalt-Regular.woff2") format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    src: url("../../assets/font/Raleway/Raleway-Regular.woff2") format('woff2'),
    url("../../assets/font/Raleway/Raleway-Regular.woff") format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
